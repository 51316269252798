import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Add axios import if not already present
import {
  IdNexus,
  Wrapper,
  WrapSetProdate,
  WrapTableItem,
  WrapSearchExport,
  // WrapLengthDepthHeight,
  WrapFactoryDetails,
  TotalPrice,
  WrapDropdown,
} from "./app-styled";
import { Notification } from "./notification";
import { LoadingSpinner } from "./components/LoaderSpiner";
import copySvg from "./img/copy.svg";
import { AddNewItemNoNexus } from "./components/AddNewItemNoNexus";
import { DeleteItem } from "./components/deleteItem";
import calculateOrderBox from "./helpers/orderBoxCalcHelper";
import { calculatePackages } from "./helpers/packageCalculation";
import { calculateVolume } from "./helpers/volumeHelper";
import ScrollButton from "./components/ScrollButton";
import { EXCELDocument } from "./components/EXCELDocument";
import { ExportPdf } from "./components/ExportPdf";
import { factoryPackage, factoryVolume } from "./helpers/productionFactory";
import { deliveryPackage, deliveryVolume } from "./helpers/deliveryCalculation";
import ExcelExtractor from "./components/PDFExtractor";

import Statistics from "./components/Statistics";
import { SortDepth } from "./components/SortDepth";
import HeightRangeCalculator from "./components/HeightRangeCalculator";
import { Logout } from "./components/logout";
import { ExportAllToExcel } from "./components/exportAllDataToExcel";

const initialVisibility = {
  // nameProduct: true,
  stock: true,
  minStock: true,
  order: true,
  totalBoxRoll: true,
  totalM: true,
  totalWeight: true,
  dateProduction: true,
  totalInProduction: true,
  shippingDay: true,
  inDelivery: true,
  sizePerBoxRoll: true,
  qtyBoxRoll: true,
  weightBoxRoll: true,
  unitPrice: true,
  // status: true,
};

export const App = () => {
  const [data, setData] = useState([]); // State to hold modified data
  const [searchTerm, setSearchTerm] = useState("");
  const [notification, setNotification] = useState("");
  const [inputChanged, setInputChanged] = useState(false);
  const [dateProdAll, setDateProdAll] = useState(false);
  const [dateDeliveryAll, setDateDeliveryAll] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedDateDelivery, setSelectedDateDelivered] = useState("");
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [selectedDeliveryIndex, setSelectedDeliveryIndex] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceProduction, setTotalPriceProduction] = useState(0);
  const [totalPriceDelivery, setTotalPriceDelivery] = useState(0);
  const [productsForStatistics, setProductsForStatistics] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState(null); // "asc", "desc", or null

  const handleSortUp = () => setSortOrder("asc");
  const handleSortDown = () => setSortOrder("desc");
  const handleSortReset = () => {
    window.location.reload();
  };

  const handleMouseEnter = (itemId) => setHoveredItemId(itemId);

  // Handle mouse leave to hide statistics
  const handleMouseLeave = () => {
    setHoveredItemId(null);
    setHoveredItem(null);
  };

  // Use effect to update `data` when `productsForStatistics` changes

  // Use effect to update `data` when `productsForStatistics` changes
  useEffect(() => {
    const updateStatisticsInBackend = async (productId, statistics) => {
      try {
        setIsLoading(true); // Start loading when API call begins
        // Call the API to update statistics in the backend
        await axios.put(`${baseURL}/api/data/statistics/${productId}`, {
          statistics,
        });
      } catch (error) {
        console.error("Error updating product statistics in backend:", error);
      } finally {
        setIsLoading(false); // Stop loading when API call completes
      }
    };

    if (productsForStatistics.length > 0) {
      setIsLoading(true); // Start loading when processing starts
      setData((prevData) =>
        prevData.map((item) => {
          // Try to find a matching product in productsForStatistics based on the name
          const matchedProduct = productsForStatistics.find((product) =>
            product.name.includes(item.den_produs),
          );

          if (matchedProduct) {
            // Update statistics for the matched product in the backend
            updateStatisticsInBackend(item._id, matchedProduct.statistics);

            // Assign the array of statistics (monthly data)
            return {
              ...item,
              statistics: matchedProduct.statistics,
            };
          }
          return item;
        }),
      );
      setIsLoading(false); // Stop loading when processing ends
    }
  }, [productsForStatistics]);

  // Save visibility state to localStorage on change
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const savedVisibility =
    JSON.parse(localStorage.getItem("columnVisibility")) || initialVisibility;
  const [visibility, setVisibility] = useState(savedVisibility);

  useEffect(() => {
    localStorage.setItem("columnVisibility", JSON.stringify(visibility));
  }, [visibility]);

  const [disableForManager, setDisableForManager] = useState(false);
  const [marianManager, setMarianaManager] = useState(false);

  const navigate = useNavigate();
  let baseURL;

  if (window.location.hostname === "localhost") {
    baseURL = "http://localhost:5000";
  } else {
    baseURL = "https://stock.veerubber.ro";
  }

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("user");
    if (isLoggedIn) {
      setDisableForManager(JSON.parse(isLoggedIn).username === "manager");
      setMarianaManager(JSON.parse(isLoggedIn).username === "mariana");
      fetch(`${baseURL}/api/data`)
        .then((response) => response.json())
        .then((data) => {
          // Calculate total price setTotalPrice
          let totalCost = 0;
          let totalCostProduction = 0;
          let totalCostDelivery = 0;

          data.forEach((item) => {
            if (item.cantitate > 0) {
              let amountToOrder = Number.isInteger(
                Math.abs(item.cantitate - item.minStock) /
                  +item.total_boxes_or_pallets,
              )
                ? Math.abs(item.cantitate - item.minStock)
                : Math.floor(
                    Math.abs(item.cantitate - item.minStock) /
                      item.total_boxes_or_pallets,
                  ) *
                    item.total_boxes_or_pallets +
                  +item.total_boxes_or_pallets;

              let costForItem = isNaN(amountToOrder)
                ? 1 * item.unitPrice
                : amountToOrder * item.unitPrice;
              let costForItemProduction = isNaN(item.total_in_production)
                ? 1 * item.unitPrice
                : item.total_in_production * item.unitPrice;
              let costForItemDelivery = isNaN(item.total_in_delivery)
                ? 1 * item.unitPrice
                : item.total_in_delivery * item.unitPrice;
              totalCost += costForItem;
              totalCostProduction += costForItemProduction;
              totalCostDelivery += costForItemDelivery;
            }
          });
          setTotalPrice(totalCost);
          setTotalPriceProduction(totalCostProduction);
          setTotalPriceDelivery(totalCostDelivery);

          // Sort and filter the fetched data
          const filteredAndSortedData = data.sort((a, b) => {
            const aCondition = a.cantitate < a.minStock && a.minStock !== 0;
            const bCondition = b.cantitate < b.minStock && b.minStock !== 0;

            if (aCondition && !bCondition) return -1;
            if (!aCondition && bCondition) return 1;

            return 0;
          });

          // Set the sorted and filtered data
          setData(filteredAndSortedData);
        })
        .catch((error) => console.error("Error fetching data:", error));
    } else {
      console.log("User is not logged in. Redirecting to login page...");
      navigate("/login");
    }
  }, [navigate, baseURL]);

  // Toggle the dropdown menu
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  const handleInputChange = (id, value, productId) => {
    setData((prevData) => {
      return prevData.map((item) => {
        if (item._id === productId) {
          return { ...item, [id]: value };
        }
        return item;
      });
    });
    setInputChanged(true);
  };

  const handleInputBlur = async (id, productId) => {
    if (!inputChanged) return;
    try {
      const updatedItem = data.find((item) => item._id === productId);
      await fetch(`${baseURL}/api/data/${productId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedItem),
      });
      setInputChanged(false);
      showNotification("Product updated");
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => {
      setNotification("");
    }, 3000); // Adjust the duration as needed
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // const filteredData = data
  //   // Sort the data based on the specified logic
  //   .sort((a, b) => {
  //     // Compare the condition where item.cantitate < item.minStock && item.minStock !== 0
  //     const aCondition = a.cantitate < a.minStock && a.minStock !== 0;
  //     const bCondition = b.cantitate < b.minStock && b.minStock !== 0;
  //
  //     // Prioritize items that meet the condition
  //     if (aCondition && !bCondition) return -1;
  //     if (!aCondition && bCondition) return 1;
  //
  //     // If both meet or both don't meet the condition, maintain the current order
  //     return 0;
  //   })
  //   // Filter the data based on the search term
  //   .filter((item) =>
  //     item.den_produs.toLowerCase().includes(searchTerm.toLowerCase()),
  //   );

  const [copied, setCopied] = useState(false);

  const handleCopyClick = (children) => {
    navigator.clipboard.writeText(children);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleClickDateProd = () => {
    setDateProdAll(!dateProdAll);
  };
  const handleClickDateDelivery = () => {
    setDateDeliveryAll(!dateDeliveryAll);
  };

  const handleItemClicked = (item) => {
    setSelectedIndex([...selectedIndex, item]);
  };
  const handleItemDeliveryClicked = (item) => {
    setSelectedDeliveryIndex([...selectedDeliveryIndex, item]);
  };

  const handleSaveDate = async () => {
    try {
      await Promise.all(
        selectedIndex.map(async (selectedItemId) => {
          const updatedItem = data.find((item) => item._id === selectedItemId);
          console.log("updatedItem", updatedItem, selectedDate);
          await fetch(`${baseURL}/api/data/${updatedItem._id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              production_date: selectedDate,
              total_in_production: updatedItem.minStock, // Set total_in_production to minStock
            }),
          });
        }),
      );
      // Update the production date and total_in_production for each selected item in the frontend
      setData((prevData) => {
        return prevData.map((item) => ({
          ...item,
          production_date: selectedIndex.includes(item._id)
            ? selectedDate
            : item.production_date,
          total_in_production: selectedIndex.includes(item._id)
            ? item.minStock // Set total_in_production to minStock
            : item.total_in_production,
        }));
      });
      setInputChanged(true);
      setDateProdAll(false);
      showNotification("Production date updated");
      setSelectedIndex([]); // Clear the selectedIndex state
    } catch (error) {
      console.error("Error updating production date:", error);
    }
  };

  const handleSaveDateDelivery = async () => {
    try {
      await Promise.all(
        selectedDeliveryIndex.map(async (selectedItemId) => {
          const updatedItem = data.find((item) => item._id === selectedItemId);
          if (updatedItem) {
            await fetch(`${baseURL}/api/data/${selectedItemId}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                delivery_date: selectedDateDelivery,
              }),
            });
          }
        }),
      );
      // Update the delivery date
      setData((prevData) => {
        return prevData.map((item) => ({
          ...item,
          delivery_date: selectedDeliveryIndex.includes(item._id)
            ? selectedDateDelivery
            : item.delivery_date,
        }));
      });
      setInputChanged(true);
      setDateDeliveryAll(false);
      showNotification("Delivery date updated");
      setSelectedDeliveryIndex([]); // Clear the selectedDeliveryIndex state
    } catch (error) {
      console.error("Error updating delivery date:", error);
    }
  };

  if (data.error) {
    return <div>{data.error}</div>;
  }

  if (data.length === 0) return <LoadingSpinner />;
  // console.log("XXXX", localStorage.getItem("user")?.username = "manager");

  // const filteredData = data.filter((item) => {
  //   const searchTermTrimmed = searchTerm.trim().toLowerCase();
  //   const denProdusTrimmed = item.den_produs.trim().toLowerCase();
  //   const denProdusEnTrimmed = item.den_produs_en.trim().toLowerCase();
  //   const idProdusTrimmed = item.id_produs.trim().toLowerCase();
  //
  //   return (
  //     denProdusTrimmed.includes(searchTermTrimmed) ||
  //     denProdusEnTrimmed.includes(searchTermTrimmed) ||
  //     idProdusTrimmed.includes(searchTermTrimmed)
  //   );
  // });

  const filteredData = data
    .sort((a, b) => {
      const aCondition = a.cantitate < a.minStock && a.minStock !== 0;
      const bCondition = b.cantitate < b.minStock && b.minStock !== 0;

      // Prioritize items that meet the condition
      if (aCondition && !bCondition) return -1;
      if (!aCondition && bCondition) return 1;

      // Additional sorting based on `quantity_dimensions_per_unit?.depth`
      if (sortOrder === "asc") {
        return (
          (a.quantity_dimensions_per_unit?.depth || 0) -
          (b.quantity_dimensions_per_unit?.depth || 0)
        );
      }
      if (sortOrder === "desc") {
        return (
          (b.quantity_dimensions_per_unit?.depth || 0) -
          (a.quantity_dimensions_per_unit?.depth || 0)
        );
      }

      // Maintain the original order if no sorting is applied
      return 0;
    })
    .filter((item) => {
      const combinedString =
        `${item.den_produs} ${item.den_produs_en} ${item.id_produs}`.toLowerCase();
      const searchWords = searchTerm.toLowerCase().split(" ");

      return searchWords.every((word) => combinedString.includes(word));
    });

  // Retrieve saved visibility state from localStorage
  // const savedVisibility =
  //   JSON.parse(localStorage.getItem("columnVisibility")) || initialVisibility;

  // State for column visibility

  // // Save visibility state to localStorage on change
  // useEffect(() => {
  //   localStorage.setItem("columnVisibility", JSON.stringify(visibility));
  // }, [visibility]);

  // Toggle the visibility of a column
  const handleVisibilityChange = (column) => {
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [column]: !prevVisibility[column],
    }));
  };

  return (
    <Wrapper className={marianManager ? "marianManager" : "anyone"}>
      <h1 className="roboto-black">
        Agroverde stock of product "VEE RUBBER CORPORATION LTD"
      </h1>
      <WrapSearchExport>
        <input
          type="text"
          placeholder="Search product"
          className="search-input"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <span style={{ display: "flex", gap: "8px" }}>
          {!disableForManager && <HeightRangeCalculator data={filteredData} />}
          {!disableForManager && (
            <div>
              <ExcelExtractor
                productsForStatistics={productsForStatistics}
                setProductsForStatistics={setProductsForStatistics}
              />
            </div>
          )}
          <WrapDropdown className="dropdown" ref={dropdownRef}>
            <button className="dropdown-button" onClick={toggleDropdown}>
              Show or Hide Columns
            </button>
            {dropdownOpen && (
              <div className="dropdown-menu">
                {Object.keys(visibility).map((column) => (
                  <div key={column} className="dropdown-item">
                    <label>{column}</label>
                    <input
                      type="checkbox"
                      checked={visibility[column]}
                      onChange={() => handleVisibilityChange(column)}
                    />
                  </div>
                ))}
              </div>
            )}
          </WrapDropdown>
          <ExportAllToExcel filteredData={filteredData} />
          <EXCELDocument filteredData={filteredData} />
          <ExportPdf filteredData={filteredData} />
          <Logout />
          {/*<UpdateFromJSON filteredData={filteredData} />*/}
          {/*<ExportFile filteredData={filteredData} />*/}
        </span>
      </WrapSearchExport>

      <WrapSetProdate>
        {!dateProdAll && (
          <button onClick={() => handleClickDateProd()}>
            Add production date
          </button>
        )}

        {dateProdAll && (
          <div>
            <p>
              Select date and check Date production checkbox to choose date when
              it will be done in factory:
            </p>
            <input
              type="date"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
            />
            <div>
              <button onClick={() => handleSaveDate()} className="save-date">
                Save Date
              </button>
              <button
                onClick={() => handleClickDateProd()}
                className="cancel-date"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </WrapSetProdate>
      <WrapSetProdate>
        {!dateDeliveryAll && (
          <button onClick={() => handleClickDateDelivery()}>
            Add in delivery date
          </button>
        )}

        {dateDeliveryAll && (
          <div>
            <p>
              Select date and check Date in delivery checkbox to choose date
              when it will be delivered:
            </p>
            <input
              type="date"
              value={selectedDateDelivery}
              onChange={(e) => setSelectedDateDelivered(e.target.value)}
            />
            <div>
              <button
                onClick={() => handleSaveDateDelivery()}
                className="save-date"
              >
                Save Date
              </button>
              <button
                onClick={() => handleClickDateDelivery()}
                className="cancel-date"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </WrapSetProdate>
      <div className="wrap-table">
        <div className="wrapper-lines desktop-menu">
          {/*{visibility.nameProduct && (*/}
          <div className="left-part name-product">
            <h2>Name product</h2>
          </div>
          {/*)}*/}
          {visibility.stock && (
            <div className="amount prod-amount">
              <h2>
                Stock
                <br />
                (pcs):
              </h2>
            </div>
          )}
          {/* Add other headers conditionally based on visibility */}
          {visibility.minStock && (
            <div className="right-part min-stock">
              <h2>
                Min. stock
                <br />
                (pcs):
              </h2>
            </div>
          )}
          {visibility.order && (
            <div className="right-part order-prod">
              <h2>Order (pcs):</h2>
            </div>
          )}
          {visibility.totalBoxRoll && (
            <div className="right-part total-q-box-roll">
              <h2>Total Box/Roll packages</h2>
            </div>
          )}
          {visibility.totalM && (
            <div className="right-part total-m">
              <h2>
                Total Box/Roll
                <br />
                (m3):
              </h2>
            </div>
          )}
          {visibility.totalWeight && (
            <div className="right-part prod-total-weight">
              <h2>Total weight (kg):</h2>
            </div>
          )}
          {visibility.dateProduction && (
            <div className="right-part prod-date-prod">
              <h2>
                Date
                <br />
                production:
              </h2>
            </div>
          )}
          {visibility.totalInProduction && (
            <div className="right-part prod-total-in-ptod">
              <h2>
                Total in production
                <br />
                (pcs):
              </h2>
            </div>
          )}
          {visibility.shippingDay && (
            <div className="right-part prod-date-delivery">
              <h2>Shipping day:</h2>
            </div>
          )}
          {visibility.inDelivery && (
            <div className="right-part prod-in-delivery">
              <h2>In delivery total (pcs):</h2>
            </div>
          )}
          {visibility.sizePerBoxRoll && (
            <div className="right-part prod-dimen">
              <h2>
                Size per Box/Roll in cm:
                <br />
                Length/Height/Depth
              </h2>
              {!disableForManager && (
                <SortDepth
                  onSortUp={handleSortUp}
                  onSortDown={handleSortDown}
                  onSortReset={handleSortReset}
                />
              )}
            </div>
          )}
          {visibility.qtyBoxRoll && (
            <div className="right-part prod-q-pal">
              <h2>Q/Box/Roll:</h2>
            </div>
          )}
          {visibility.weightBoxRoll && (
            <div className="right-part prod-weight-pal">
              <h2>
                Weight
                <br />
                box/roll:
              </h2>
            </div>
          )}
          {visibility.unitPrice && (
            <div className="right-part unit-price">
              <h2>
                Unit
                <br />
                price
              </h2>
            </div>
          )}
          {visibility.status && (
            <div className="right-part hideIt">
              <h2>Status:</h2>
            </div>
          )}
        </div>
        {filteredData.map((item, index) => {
          if (
            item.id_produs === "195062(1)" ||
            item.id_produs === "195059(1)"
          ) {
            return;
          }
          if (
            item.production_date !== null &&
            item.production_date.split("T")[0] <=
              new Date().toISOString().split("T")[0]
          ) {
            // Update the data for items meeting the condition
            // Set total_in_delivery = total_in_production
            // Set production_date to null
            // Set total_in_production to 0
            const updatedItem = {
              ...item,
              total_in_delivery: item.total_in_production,
              production_date: null,
              total_in_production: 0,
            };

            // Update the item in the data array
            setData((prevData) => {
              return prevData.map((prevItem) => {
                if (prevItem._id === item._id) {
                  return updatedItem;
                }
                return prevItem;
              });
            });

            // Perform the API call to update the item in the backend
            fetch(`${baseURL}/api/data/${item._id}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(updatedItem),
            })
              .then((response) => response.json())
              .then((data) => {
                console.log("Item updated in backend:", data);
                // Optionally, you can show a notification or handle success/error here
              })
              .catch((error) => {
                console.error("Error updating item in backend:", error);
                // Optionally, you can show a notification or handle the error here
              });
          }

          return (
            <WrapTableItem
              key={index}
              className={
                item.cantitate < item.minStock && item.minStock !== ""
                  ? calculateOrderBox(item) -
                      item.total_in_production -
                      item.total_in_delivery <=
                    0
                    ? "wrapper-lines inprogress-color"
                    : "wrapper-lines warning-color"
                  : "wrapper-lines"
              }
            >
              {/*{visibility.nameProduct && (*/}
              <div className="left-part name-product">
                {!disableForManager && (
                  <IdNexus
                    onClick={() => handleCopyClick(item.id_produs)}
                    style={
                      !disableForManager && item.id_produs === "00000"
                        ? { background: "orange" }
                        : {}
                    }
                  >
                    <span>
                      <img src={copySvg} alt="copy" />
                    </span>{" "}
                    id nexus: {item.id_produs}
                  </IdNexus>
                )}
                {!disableForManager && item.id_produs === "00000" && (
                  <DeleteItem
                    productId={item._id}
                    setData={setData}
                    showNotification={showNotification}
                  />
                )}
                <div className="show-mobile-menu">Name product:</div>
                <input
                  type="text"
                  value={
                    item.den_produs_en === ""
                      ? item?.den_produs
                      : item.den_produs_en
                  }
                  placeholder={
                    item.den_produs_en === ""
                      ? item?.den_produs
                      : item.den_produs_en
                  }
                  onChange={(e) =>
                    handleInputChange("den_produs_en", e.target.value, item._id)
                  }
                  onBlur={() => handleInputBlur("den_produs_en", item._id)}
                />
              </div>
              {/*)}*/}
              {visibility.stock && (
                <div className="amount prod-amount">
                  <span className="show-mobile-menu">Stock: </span>
                  <span className="color-green">{item.cantitate}</span>
                </div>
              )}

              {visibility.minStock && (
                <div
                  className={
                    disableForManager
                      ? "right-part min-stock disable-it"
                      : "right-part min-stock"
                  }
                >
                  <span className="show-mobile-menu">Min. stock(pcs): </span>
                  <input
                    type="number"
                    min="0"
                    value={item.minStock}
                    onChange={(e) =>
                      handleInputChange("minStock", e.target.value, item._id)
                    }
                    onWheel={(event) => event.target.blur()}
                    onBlur={() => handleInputBlur("minStock", item._id)}
                  />
                  {!disableForManager && (
                    <Statistics
                      item={item}
                      hoveredItemId={hoveredItemId}
                      handleMouseEnter={handleMouseEnter}
                      handleMouseLeave={handleMouseLeave}
                    />
                  )}
                </div>
              )}

              {visibility.order && (
                <div className="right-part order-prod">
                  {item.cantitate < item.minStock && item.minStock !== 0 ? (
                    <>
                      <span className="show-mobile-menu">Order(pcs): </span>
                      <span className="warning">
                        {isNaN(calculateOrderBox(item)) ||
                        calculateOrderBox(item) -
                          item.total_in_production -
                          item.total_in_delivery <=
                          0
                          ? 0
                          : calculateOrderBox(item) -
                            item.total_in_production -
                            item.total_in_delivery}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="show-mobile-menu">Order: </span>
                      <span
                        style={{
                          background: "#ccc",
                          padding: "0 9px 2px",
                          opacity: "0.5",
                        }}
                      >
                        0
                      </span>
                    </>
                  )}
                </div>
              )}

              {visibility.totalBoxRoll && (
                <div className="right-part total-q-box-roll">
                  <span className="show-mobile-menu">
                    Total Box/Roll packages:
                  </span>
                  <span>
                    {item.minStock === 0 ||
                    calculateOrderBox(item) -
                      item.total_in_production -
                      item.total_in_delivery <=
                      0
                      ? 0
                      : isNaN(calculatePackages(item))
                        ? 0
                        : calculatePackages(item)}
                  </span>
                </div>
              )}

              {visibility.totalM && (
                <div className="right-part total-m">
                  <span className="show-mobile-menu">Total m3:</span>
                  <span>
                    {isNaN(calculateVolume(item, calculatePackages(item)))
                      ? 0
                      : Math.abs(
                          calculateVolume(item, calculatePackages(item)),
                        )}
                  </span>
                </div>
              )}

              {visibility.totalWeight && (
                <div className="right-part prod-total-weight">
                  <span className="show-mobile-menu">Total weight (kg):</span>
                  <span>
                    <span>
                      {isNaN(
                        calculatePackages(item) * item.weight_bax_pallet,
                      ) ||
                      calculateOrderBox(item) -
                        item.total_in_production -
                        item.total_in_delivery <=
                        0
                        ? 0
                        : (
                            calculatePackages(item) * item.weight_bax_pallet
                          ).toFixed(3)}
                    </span>
                  </span>
                </div>
              )}

              {visibility.dateProduction && (
                <div className="right-part prod-date-prod">
                  <span className="show-mobile-menu">Date production:</span>
                  {/*{dateProdAll && !item.production_date ? (*/}
                  {dateProdAll ? (
                    <input
                      type="checkbox"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleItemClicked(item._id)}
                    />
                  ) : (
                    <input
                      type="date"
                      value={
                        item.production_date
                          ? new Date(item.production_date)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          "production_date",
                          e.target.value,
                          item._id,
                        )
                      }
                      onBlur={() =>
                        handleInputBlur("production_date", item._id)
                      }
                    />
                  )}
                </div>
              )}

              {visibility.totalInProduction && (
                <div className="right-part prod-total-in-ptod move-bottom">
                  <span className="show-mobile-menu">Total in production:</span>
                  {!disableForManager && (
                    <WrapFactoryDetails>
                      kg: {factoryPackage(item) * item.weight_bax_pallet} v:{" "}
                      {factoryVolume(item)}
                    </WrapFactoryDetails>
                  )}

                  <input
                    type="number"
                    min="0"
                    onWheel={(event) => event.target.blur()}
                    value={item.total_in_production}
                    onChange={(e) =>
                      handleInputChange(
                        "total_in_production",
                        e.target.value,
                        item._id,
                      )
                    }
                    onBlur={() =>
                      handleInputBlur("total_in_production", item._id)
                    }
                  />
                </div>
              )}

              {visibility.shippingDay && (
                <div className="right-part prod-date-delivery">
                  <span className="show-mobile-menu">Shipping day:</span>
                  {dateDeliveryAll ? (
                    <input
                      style={{ cursor: "pointer" }}
                      type="checkbox"
                      onClick={() => handleItemDeliveryClicked(item._id)}
                    />
                  ) : (
                    <input
                      type="date"
                      value={
                        item.delivery_date
                          ? new Date(item.delivery_date)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      onChange={(e) =>
                        handleInputChange(
                          "delivery_date",
                          e.target.value,
                          item._id,
                        )
                      }
                      onBlur={() => handleInputBlur("delivery_date", item._id)}
                    />
                  )}
                </div>
              )}

              {visibility.inDelivery && (
                <div className="right-part prod-in-delivery move-bottom">
                  <span className="show-mobile-menu">In delivery total:</span>
                  {!disableForManager && (
                    <WrapFactoryDetails>
                      kg: {deliveryPackage(item) * item.weight_bax_pallet} v:{" "}
                      {deliveryVolume(item)}
                    </WrapFactoryDetails>
                  )}
                  <input
                    type="number"
                    min="0"
                    onWheel={(event) => event.target.blur()}
                    value={item.total_in_delivery}
                    onChange={(e) =>
                      handleInputChange(
                        "total_in_delivery",
                        e.target.value,
                        item._id,
                      )
                    }
                    onBlur={() =>
                      handleInputBlur("total_in_delivery", item._id)
                    }
                  />
                </div>
              )}

              {visibility.sizePerBoxRoll && (
                <div className="right-part prod-dimen">
                  <span className="show-mobile-menu">
                    Dimensions per unit:{" "}
                  </span>
                  <input
                    type="number"
                    min="0"
                    onWheel={(event) => event.target.blur()}
                    value={item.quantity_dimensions_per_unit?.length || ""}
                    onChange={(e) =>
                      handleInputChange(
                        "quantity_dimensions_per_unit",
                        {
                          ...item.quantity_dimensions_per_unit,
                          length: e.target.value,
                        },
                        item._id,
                      )
                    }
                    onBlur={() =>
                      handleInputBlur("quantity_dimensions_per_unit", item._id)
                    }
                    placeholder="Length"
                  />
                  <input
                    type="number"
                    min="0"
                    onWheel={(event) => event.target.blur()}
                    value={item.quantity_dimensions_per_unit?.height || ""}
                    onChange={(e) =>
                      handleInputChange(
                        "quantity_dimensions_per_unit",
                        {
                          ...item.quantity_dimensions_per_unit,
                          height: e.target.value,
                        },
                        item._id,
                      )
                    }
                    onBlur={() =>
                      handleInputBlur("quantity_dimensions_per_unit", item._id)
                    }
                    placeholder="Height"
                  />
                  <input
                    type="number"
                    min="0"
                    onWheel={(event) => event.target.blur()}
                    value={item.quantity_dimensions_per_unit?.depth || ""}
                    onChange={(e) =>
                      handleInputChange(
                        "quantity_dimensions_per_unit",
                        {
                          ...item.quantity_dimensions_per_unit,
                          depth: e.target.value,
                        },
                        item._id,
                      )
                    }
                    onBlur={() =>
                      handleInputBlur("quantity_dimensions_per_unit", item._id)
                    }
                    placeholder="Depth"
                  />
                </div>
              )}

              {visibility.qtyBoxRoll && (
                <div className="right-part prod-q-pal">
                  <span className="show-mobile-menu">Q/Box/Roll:</span>
                  <input
                    type="number"
                    min="0"
                    onWheel={(event) => event.target.blur()}
                    value={item.total_boxes_or_pallets}
                    onChange={(e) =>
                      handleInputChange(
                        "total_boxes_or_pallets",
                        e.target.value,
                        item._id,
                      )
                    }
                    onBlur={() =>
                      handleInputBlur("total_boxes_or_pallets", item._id)
                    }
                  />
                </div>
              )}

              {visibility.weightBoxRoll && (
                <div className="right-part prod-weight-pal">
                  <span className="show-mobile-menu">Weight box/roll:</span>
                  <input
                    type="number"
                    min="0"
                    onWheel={(event) => event.target.blur()}
                    value={item.weight_bax_pallet}
                    onChange={(e) =>
                      handleInputChange(
                        "weight_bax_pallet",
                        e.target.value,
                        item._id,
                      )
                    }
                    onBlur={() =>
                      handleInputBlur("weight_bax_pallet", item._id)
                    }
                  />
                </div>
              )}

              {visibility.unitPrice && (
                <div className="right-part unit-price">
                  <span className="show-mobile-menu">Unit Price:</span>
                  <input
                    type="number"
                    min="0"
                    onWheel={(event) => event.target.blur()}
                    value={item.unitPrice}
                    onChange={(e) =>
                      handleInputChange("unitPrice", e.target.value, item._id)
                    }
                    onBlur={() => handleInputBlur("unitPrice", item._id)}
                  />
                </div>
              )}

              {visibility.status && (
                <div className="right-part hideIt">
                  <span className="show-mobile-menu">Status: </span>
                  <input
                    type="checkbox"
                    checked={item.status === "true"}
                    onChange={(e) =>
                      handleInputChange(
                        "status",
                        e.target.checked ? "true" : "false",
                        item._id,
                      )
                    }
                    onBlur={() => handleInputBlur("status", item._id)}
                  />
                  Is ordered
                </div>
              )}
            </WrapTableItem>
          );
        })}
      </div>
      {notification && <Notification message={notification} />}
      {copied && <Notification message="Copied!" />}
      {!disableForManager && (
        <AddNewItemNoNexus
          showNotification={showNotification}
          setData={setData}
        />
      )}
      <ScrollButton />
    </Wrapper>
  );
};
